<template>
  <ContainerBasic
    class="px-sm print:max-w-[720px]"
    container-type="PAGE"
    :style="pageBreak(blockData.printPageBreak)"
  >
    <div
      class="grid items-center w-full grid-cols-1 pt-sm touch:md:pt-0 lg:pt-0 md:grid-cols-12 print:grid-cols-12 gap-y-sm touch:md:gap-y-0 touch:md:gap-x-8 lg:gap-y-0 lg:gap-x-8 print:gap-x-8 print:pl-16 print:pr-16"
      :style="[blockData.bgColor]"
    >
      <div
        class="col-span-6"
        :class="[
          {
            'order-2': shouldBeReordered,
          },
        ]"
      >
        <template v-if="blockData.link">
          <NuxtLink :to="blockData.link">
            <ImageDetailed :image-data="blockData.image" :show-meta="false" />
          </NuxtLink>
        </template>
        <template v-else>
          <ImageDetailed :image-data="blockData.image" :show-meta="false" />
        </template>
      </div>

      <div
        class="relative flex h-full col-span-6 md:mx-xl py-sm"
        :class="[
          {
            'order-1': shouldBeReordered,
          },
          blockData.textColor?.indexOf('ffffff') > -1 ||
          blockData.textColor?.indexOf('white') > -1
            ? 'fill-white stroke-white'
            : '',
        ]"
        :style="[blockData.textColor]"
      >
        <ImageBasic
          v-if="blockData.bgImage"
          :image-data="blockData.bgImage"
          class="!absolute -z-10 w-full top-0 md:-top-sm"
        />
        <div class="flex flex-row align-self-center">
          <div class="self-center">
            <atom-circle-icon
              v-if="blockData.icon"
              :icon="blockData.icon"
              class="mb-sm md:mb-md"
              inverted
            />

            <Headline
              v-if="blockData.headline"
              :headline="blockData.headline"
              :has-decoration="true"
              :level="'h2'"
              class="!mb-sm mt-sm"
            />

            <WYSIWYGContentRaw
              class="pt-3 !px-sm"
              :content="blockData.text"
              inner-classes="!px-0"
              :no-container-padding="true"
            />

            <div v-if="blockData.cta" class="mt-auto pt-sm">
              <CallToAction :block-data="blockData.cta" class="!w-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContainerBasic>
</template>

<script lang="ts" setup>
import { EElementOrientationType } from '@/@types/element-orientation';
import ContainerBasic from '@/components/components/container-basic.vue';
import AtomCircleIcon from '@/components/atom/circle-icon.vue';
import CallToAction from '~~/src/components/block/cta/vdv/CallToAction.vue';
import ImageDetailed from '@/components/components/cms/components/image-detailed.vue';
import { Headline, WYSIWYGContentRaw } from '~/complib';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { TextImageData } from '../blockType';

const isMobile = inject(mq_breakpointIsMobile);
const props = defineProps({
  blockData: {
    type: Object as PropType<TextImageData>,
    required: true,
    default: {} as TextImageData,
  },
});

const isMounted = ref(false);

const shouldBeReordered = computed(() => {
  return (
    isMounted.value &&
    !isMobile.value &&
    props.blockData.imageOrientation === EElementOrientationType.RIGHT
  );
});

function pageBreak(pageBreak: any) {
  if (pageBreak === 'break-before') {
    return 'page-break-before: always';
  }
  if (pageBreak === 'break-after') {
    return 'page-break-after: always';
  }

  return '';
}

onMounted(() => {
  // Force Client-Side shouldBeReordered update / rerender
  // @see https://github.com/vuejs/core/issues/5063
  isMounted.value = true;
});
</script>
<style scoped lang="postcss"></style>
